import { doRequest } from '@/utils/request';

import * as urls from '../apiUrl';

const getAllProvincesData = async (
  locale: any = 'en',
  brand: string = 'dp'
) => {
  return doRequest(`${urls.getAllProvinces(locale, brand)}`, 'get');
};

const getStoreData = async (
  province: any,
  locale: any = 'en',
  brand: string = 'dp'
) => {
  return doRequest(`${urls.getProviStore(province, locale, brand)}`, 'get');
};

export { getAllProvincesData, getStoreData };
